import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import TutorTraining from "./components/TutorTraining";  
import ClassComplete from "./components/ClassComplete"; 
import LaunchTutorSession from "./agora/LaunchTutorSession";
import PrivateTutorTraining from "./components/PrivateTutorTraining";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route 
          path="/:sessionID/:userUid/:idToken" 
          element={
            <TutorTraining />
          } 
        />
        <Route 
          path="/hire-class-room/:sessionID" 
          element={
            <LaunchTutorSession />
          } 
        />

        {/* <Route 
          path="/private-training/:sessionID/:userUid/:idToken/:AdminID/:AdminName/:AdminProfilePic/:TutorID/:TutorName/:TutorProfilePic" 
          element={
            <PrivateTutorTraining />
          } 
        /> */}

        <Route 
          path="/private-training/:sessionID/:userUid/:idToken/:AdminID/:AdminName/:TutorID/:TutorName" 
          element={
            <PrivateTutorTraining />
          } 
        />

        <Route 
          path="/class-complete" 
          element={
            <ClassComplete />
          } 
        />
      </Routes>
    </Router>
  );
};

export default App;
