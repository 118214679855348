import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { decryptData, agoraSessionStart, agoraSessionEnd } from "../services/service";

const LaunchTutorSession = () => {
  const { sessionID } = useParams();
  const sessionType = sessionStorage.getItem("SessionType");
  const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
  const userUid = userInfo?.uid;
  const sessionId = decryptData(sessionID);
  const agoraCallDuration = 3600 ;
  const rtmToken = JSON.parse(localStorage.getItem("rtmToken"));
  const roomName = sessionType === 'PrivateSession' ? `TrainingMode${sessionId}` : `meeting${sessionId}`;
  const roomUuid = sessionType === 'PrivateSession' ? `TrainingModeuuid${sessionId}` : `meetinguuid${sessionId}`;
  const channel = `channel${sessionId}`;
  const roomType = 4;
  const userName = 'Admin';

  useEffect(() => {
    localStorage.setItem("SessionType", "TSS");
    localStorage.setItem("CallType", 1);

    const params = {
      userUid,
      userName,
      roomUuid,
      sessionId,
      roomName,
      channel,
      rtmToken,
      roomType,
      agoraCallDuration,
      agoraSessionStart,
      agoraSessionEnd,
    };
    test(params); 

  }, []);
  return;
};
export default LaunchTutorSession;
