import axios from 'axios';
import CryptoJS from 'crypto-js';
import { APIClient } from "./api";
const api = new APIClient();

axios.interceptors.request.use((config) => {
    config.headers["environment"] = 'Dev';
    return config;
});

const SECRET_KEY = "mYse&retkEy";
const API_BASE_URL = "https://us-central1-yasa-21022022.cloudfunctions.net";

export const generateAgoraToken = async (userUid, role, channelName) => {
    const data = {
      uid: userUid,
      RoleID: role,
      channelName: channelName,
    };

    const response = await api.create(`${API_BASE_URL}/GenerateAgoraToken`, data);
    return response.Data;
};


// export const agoraSessionStart = async (userName, roomType, sessionId, userUid, roomUuid, rtmToken) => {
//   try {
//     const whiteBoardConfig = JSON.parse(localStorage.getItem('whiteBoardConfig'));
//     const data = {
//       SessionStatus: 2,
//       SessionID: sessionId,
//       ChannelName: `channel${sessionId}`,
//       UserUID: userUid,
//       RoomUUID: roomUuid,
//       UserName: userName,
//       RoomType: roomType,
//       RTMToken: rtmToken,
//       WhiteBoardToken: whiteBoardConfig?.roomToken,
//       WhiteBoardID: whiteBoardConfig?.roomId
//     }
//     await api.create(`${API_BASE_URL}/UpdateSessionStatus`, data);
//   } catch (e) {
//     console.log('e: ', e);
//   }
// }

export const agoraSessionStart = async (userName, roomType, sessionId, userUid, roomUuid, rtmToken) => {
  try {
    const checkWhiteBoardConfig = () => {
      const whiteBoardConfig = JSON.parse(localStorage.getItem('whiteBoardConfig'));
      return whiteBoardConfig;
    };

    const waitForWhiteBoardConfig = async (interval = 2000, timeout = 10000) => {
      const startTime = Date.now();
      return new Promise((resolve, reject) => {
        const checkInterval = setInterval(() => {
          const whiteBoardConfig = checkWhiteBoardConfig();
          if (whiteBoardConfig) {
            clearInterval(checkInterval);
            resolve(whiteBoardConfig);
          } else if (Date.now() - startTime > timeout) {
            clearInterval(checkInterval);
            reject(new Error('WhiteBoard configuration is not available within the timeout period.'));
          }
        }, interval);
      });
    };

    // Wait for whiteBoardConfig to be available
    const whiteBoardConfig = await waitForWhiteBoardConfig();

    const data = {
      SessionStatus: 2,
      SessionID: sessionId,
      ChannelName: `channel${sessionId}`,
      UserUID: userUid,
      RoomUUID: roomUuid,
      UserName: userName,
      RoomType: roomType,
      RTMToken: rtmToken,
      WhiteBoardToken: whiteBoardConfig.roomToken,
      WhiteBoardID: whiteBoardConfig.roomId
    };

    // Call the API
    await api.create(`${API_BASE_URL}/UpdateSessionStatus`, data);

  } catch (e) {
    console.log('Error: ', e);
  }
};


export const agoraSessionEnd = async (sessionId, userUid, roomUuid, rtmToken ) => {
  // const callDuration = localStorage.getItem('CallDuration');
  // const callDurationSeconds = convertCallDurationToSeconds(callDuration);
  const data = {
    SessionStatus: 3,
    SessionID: sessionId,  
    UserUID: userUid, 
    RoomUUID: roomUuid,
    RTMToken: rtmToken
    // CallDuration: callDurationSeconds.toString(),
  }
  try {
    await api.create(`${API_BASE_URL}/UpdateSessionStatus`, data);

    setTimeout(() => {
      window.location.pathname = '/class-complete';
    }, 100)

  } catch (e) {
    window.location.pathname = '/class-complete';
  }
}

function convertCallDurationToSeconds(callDuration) {
  // Split the time string into hours, minutes, and seconds
  let parts = callDuration.split(' : ');

  // Convert each part to an integer
  let hours = parseInt(parts[0], 10);
  let minutes = parseInt(parts[1], 10);
  let seconds = parseInt(parts[2], 10);
  
  // Calculate the total number of seconds
  let totalSeconds = (hours * 3600) + (minutes * 60) + seconds;
  
  return totalSeconds;
}
  

export const encryptData = (data) => {
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    SECRET_KEY
  ).toString();
  return encrypted.replace(/\//g, "AGORA");
};
  
export const decryptData = (encrypt) => {
  const encrypted = encrypt.replace(/AGORA/g, "/");
  const decrypted = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );
  return decrypted;
};