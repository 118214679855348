import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { generateAgoraToken, decryptData } from '../services/service';

import "../App.css";

const TutorTraining = () => {
    const navigate = useNavigate()
    const { sessionID, userUid, idToken } = useParams();
    const sessionId = decryptData(sessionID);

    const handleJoin = () => {
        const tutorResponseData = {};
        tutorResponseData["TutorID"] = '796';
        tutorResponseData["TutorName"] = 'Admin';
        tutorResponseData["TutorImage"] = 'https://storage.googleapis.com/yasa-admin_images/StudentAvatar/MaleDefaultImg.png';
        tutorResponseData["StudentID"] = '0';
        tutorResponseData["StudentName"] = 'Admin';
        tutorResponseData["StudentImage"] = 'https://storage.googleapis.com/yasa-admin_images/StudentAvatar/MaleDefaultImg.png';
    
        sessionStorage.setItem("TutorResponse", JSON.stringify(tutorResponseData));

        const profileInfo = {
            UserName: 'Admin',
            ProfilePictureUrl: 'https://storage.googleapis.com/yasa-admin_images/StudentAvatar/MaleDefaultImg.png'
        }

        sessionStorage.setItem('userInfo', JSON.stringify({uid: userUid}));
        sessionStorage.setItem('ProfileInfo', JSON.stringify(profileInfo));
        sessionStorage.setItem('loginToken', JSON.stringify({idToken: idToken}));

        generateAgoraToken(userUid, 1, `channel${sessionId}`).then((res) => {
            localStorage.setItem("rtmToken", JSON.stringify(res.RtmToken));
            localStorage.setItem("rtcToken", JSON.stringify(res.RtcToken));
            navigate(`/hire-class-room/${sessionID}`)
        })
    }

    useEffect(() => {
        setTimeout(() => {
         handleJoin()
        }, 1000)
           
    }, [])

  return (
    <>
        <div className="loader-box">
            <div className="loader"></div>
        </div>
        <h1 className="text-center">Loading...</h1>
    </>
  )
}

export default TutorTraining